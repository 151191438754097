import { Suspense, lazy } from 'react'
import * as analytics from './helpers/analytics'
import { IConfig, IPaywall } from './interfaces'
import { findPlayerElements, getMediaPlayer } from './video-players'
import { getDeviceType, removeAuthToken, removeRefreshToken } from './helpers'
import { defaultFunctionSelector, getContainer, isDarkTheme } from './helpers/dom'
import { ErrorBoundary } from 'react-error-boundary'
import { IPlayer, PlayerEvents } from './video-players/IPlayer'
import { Provider } from 'react-redux'
import { emitter } from './services/eventemitter'
import { fc_logger } from './helpers/fc_logger'
import { addContentRestriction, canShowOverlay, updateConfig } from './actions/paywall'
import { v4 as uuidv4 } from 'uuid'
import { createRoot, Root } from 'react-dom/client'
import { storeBuilder } from './store'
//import App from './App'
const App = lazy(() => import(/* webpackChunkName: "App" */ './App'))

const getContentType = (mediaPlayer: string | undefined) => {
  if (mediaPlayer) {
    return ['fc_audio'].includes(mediaPlayer)
      ? 'audio'
      : ['fc_video', 'fc_dailymotion', 'fc_jwplayer', 'fc_brightcove', 'fc_vimeo'].includes(
          mediaPlayer
        )
      ? 'video'
      : 'text'
  }
  return 'text'
}

declare global {
  interface Window {
    Paywall: IPaywall
    VideoPaywall: Function
    AudioPaywall: Function
    ifrm?: HTMLElement
    focusOnWall?: Function
    paywallInstance: PaywallClass | any
  }
}

const SharedActions = ['LOGOUT', 'RESET_AUTH', 'SET_USER', 'SET_IS_LOGGED_OUT']
const fewcentsSDKContainer = 'fc-sdk'
let mediaOverlay: any
class PaywallClass {
  private root: Root | null = null
  public config: IConfig | any = {}
  private readonly containerId: string = fewcentsSDKContainer + uuidv4()
  private unlockElement: HTMLElement | null | undefined = null
  private unlockDivParent: HTMLElement | null = null
  private store: any
  public static data = new Map()
  private previousEvent: string = ''

  constructor(config: IConfig) {
    const that = this
    const device = getDeviceType()
    
    let deactivatedPublishers: string[] = [
      '3dfeb3ee-bf35-4697-acb4-31dcf3190475', 
      '5c056c7d-2bbb-4213-a9d2-c42065ba164c',
      'dbc85440-73df-49bf-9772-b0749a6f44a6',
      '0e802495-5520-49fd-bde7-d648d2761c13',
      '68366f6e-43ee-40d9-a1ce-29fc812da5ff',
      'dca36182-0b2b-447d-a6e6-3d035032a312',
      '09ccbd08-aaf2-486f-bae0-a57b02a734d5',
      'ebe56f46-a2ff-4799-a6e9-1310f4e7d690',
      '09eb8d12-8fd2-4bea-991a-3b3a4c5c40f7',
      'ccdeb586-632d-4401-8aea-08622758a446',
      '2e388dba-7f3f-4753-acda-050ec588c8cd',
      '68049a7c-3086-49ef-b6de-a1524db390e6',
      'cd83f26a-894c-4d1a-b3c1-63d82d25fbbb',
      'ff2eeac7-95a7-4aae-a3ce-510df9cdcc94',
      'f90739be-a2fe-4839-8a94-585054dd92e3'
    ]

    if (device === true) {
      analytics.track(['bot_detected'], {})
    } else {
      that.config = config

      const exists: boolean = deactivatedPublishers.includes(that.config.accessKey);
      console.log("fc_sdk - Bypassing Fewcents Paywall - ", exists);
      if (!exists) {
        that.store = storeBuilder()
        that.config.track = that.track.bind(that)
        that.initDefaultConfig(that.config)
        const init = that.config.isMedia ? that.initMedia.bind(that) : that.initText.bind(that)

        init(that.config).then(() => {
          that.initObserver()
          that.render()
          that.store.dispatch(updateConfig(that.config))
          config.track(['constructor_called', that.store], {
            isLinkout: that.config.isLinkout,
          });
        })
      }
    }
  }

  async track(args: Array<any>, bidDetails?: any) {
    if (!bidDetails) {
      bidDetails = this.store.getState()?.paywall?.fewCentsBidResponse || {}
    }
    if (this.config.player?.analyticsData) {
      bidDetails = {
        ...bidDetails,
        player: this.config.player?.analyticsData.player,
      }
    }
    bidDetails.isLinkout = this.config.isLinkout
    if (args[0] !== 'browser_closed') {
      this.previousEvent = args[0]
    } else {
      bidDetails.previousEvent = this.previousEvent
    }
    analytics.track(args, bidDetails)
  }

  tearDown() {
    // emitter.offAll()
    PaywallClass.data.delete(this.containerId)
    this.root?.unmount()
    const containerElement = getContainer(this.containerId)
    containerElement.remove()
  }

  hideMediaOverlay() {
    const containerElement = getContainer(this.containerId)
    if (containerElement) {
      containerElement.style.zIndex = '-1'
    }
  }

  showMediaOverlay() {
    const containerElement = getContainer(this.containerId)
    if (containerElement) {
      containerElement.style.zIndex = 'unset'
    }
  }

  private setLayout(args: any) {
    const that = this
    mediaOverlay = setTimeout(() => {
      clearTimeout(mediaOverlay)
      if (that.unlockElement) {
        const bidMode =
          that.store?.getState()?.paywall?.fewCentsBidResponse?.fewCentsTransactionMode
        that.store.dispatch(canShowOverlay(that.unlockElement, bidMode === 'tipjar'))
      }
    }, 200)
  }

  initDefaultConfig(config: IConfig) {
    config.contentSelector =
      config.contentSelector === '$window.location.href'
        ? window.location.href
        : config.contentSelector
    config.contentType = config.contentType || 'text'
    config.bubblePosition = config.bubblePosition || 'right'
    config.contentPreviewHeight = config.contentPreviewHeight || 200
    config.lastVisibleElement = config.lastVisibleElement || ''
    config.isPreviewTransparent = !!config.isPreviewTransparent
    config.monetizeType = config.monetizeType || 'paywall'
    config.isLinkout = Boolean(config.isLinkout)
    config.borderless = Boolean(config.isLinkout || config.borderless)
    config.allowPointerEvents = Boolean(config.allowPointerEvents)
    config.isHorizontal = Boolean(config.isHorizontal) || false
    config.backgroundColor = config.backgroundColor || '#ffffff'
    config.secondaryColor = config.secondaryColor || '#111111'
    config.borderColor = config.borderColor || '#d3d3d3'
    config.functionSelector = config.functionSelector || defaultFunctionSelector
    config.isDarkTheme = isDarkTheme(config.backgroundColor)
    config.fontFamily = config.fontFamily
      ? config.fontFamily
      : document.body
      ? window.getComputedStyle(document.body).fontFamily
      : 'Arial'
    config.unlockOnLoad = true
    config.mobileOffset = {
      vertical: '20%',
      horizontal: '1%',
      ...(config.mobileOffset || {}),
    }

    config.desktopOffset = {
      vertical: '20%',
      horizontal: '2%',
      ...(config.desktopOffset || {}),
    }

    if (config.uxMode === undefined || config.uxMode === null) {
      config.uxMode = 'window'
    }
    config.bubbleOpenWidgetPosition = config.bubbleOpenWidgetPosition || '32px'
  }

  private async initText(config: IConfig) {
    this.unlockElement = await config.functionSelector()
    if (this.unlockElement) {
      if (!config?.unlockOnLoad) {
        addContentRestriction(config, this.unlockElement)
        if (this.unlockElement.style.height !== '') {
          this.unlockElement.style.removeProperty('height')
        }
      }
      const ContainerElement = getContainer(this.containerId)
      this.unlockDivParent = this.unlockElement.parentElement

      if (PaywallClass.data.has(this.containerId)) {
        if (this.unlockDivParent) {
          this.unlockDivParent.style.position = 'relative'
          this.unlockDivParent.replaceChild(
            ContainerElement,
            this.unlockElement.nextSibling as Node
          )
        }
      } else {
        PaywallClass.data.set(this.containerId, {
          ContainerElement,
          store: this.store,
        })

        this.unlockDivParent &&
          this.unlockDivParent.insertBefore(ContainerElement, this.unlockElement.nextSibling)
      }
    }
  }

  private async initMedia(config: IConfig) {
    this.unlockElement = config.paywallTargetElement
    if (this.config.player) {
      this.config.player.init(this.containerId, config.track)
      const setLayout = this.setLayout.bind(this)
      emitter.on(PlayerEvents.fullscreenChange, setLayout)
      emitter.on(PlayerEvents.screenResize, setLayout)
      if (!PaywallClass.data.has(this.containerId)) {
        PaywallClass.data.set(this.containerId, {
          ContainerElement: getContainer(this.containerId, true),
          store: this.store,
        })
      }
      config.track(
        [
          'new_media_player_initialized',
          { source: config.articleIdentifier, mediaPlayer: config.mediaPlayer },
        ],
        {}
      )
      setLayout(null)
    }
  }

  initObserver() {
    const ContainerElement = getContainer(this.containerId)
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.removedNodes.forEach((removedNode) => {
          if (removedNode === this.unlockElement) {
            PaywallClass.data.delete(this.containerId)
            ContainerElement.remove()

            if (PaywallClass.data.size === 0) {
              observer.disconnect()
            }
          }
        })
      })
    })

    observer.observe(this.unlockElement?.parentNode as Node, {
      childList: true,
    })
  }

  renderSDK() {
    const ContainerElement = getContainer(this.containerId)
    const renderTime = Date.now()
    const that = this
    this.store.subscribe(() => {
      const now = Date.now()
      if (now - renderTime <= 6000) {
        return
      }
      const {
        lastAction: { type, payload },
      } = this.store.getState()

      if (!SharedActions.includes(type) || payload?.isShared) return

      Array.from(PaywallClass.data).forEach(([containerId, { store }]) => {
        if (this.containerId !== containerId) {
          store.dispatch({
            type,
            payload: { ...payload, isShared: true },
          })
        }
      })
    })

    async function handleRenderError(error: any) {
      const mess = await error
      console.error('FC Plugin render failed', mess)
      that.config.track(['render_fc_plugin_failed', { reason: error }])
    }

    function ErrorFallback(props: any) {
      /** TODO: Add UI to be shown when there is render error */
      return <div></div>
    }
    this.root = createRoot(ContainerElement!) // createRoot(container!) if you use TypeScript
    this.root.render(
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleRenderError}>
        <Provider store={this.store}>
          <Suspense fallback={<div>...</div>}>
            <App />
          </Suspense>
        </Provider>
      </ErrorBoundary>
    )
  }

  render() {
    this.renderSDK()
  }
}

window.paywallInstance = { tearDown: () => {} }

function Paywall(config: any) {
  config.isMedia = ['audio', 'video'].includes(config.contentType)
  if (config.isMedia || !config.infiniteScroll) {
    window.paywallInstance = new PaywallClass(config)
  } else {
    config.functionSelector = config.functionSelector || defaultFunctionSelector
    config.functionSelector().then(() => {
      const articleIdentifier =
        window?.paywallInstance?.store?.getState()?.paywall?.fewCentsBidResponse?.articleIdentifier
      console.info('Previous article identifier: ', articleIdentifier)
      console.info('New article identifier: ', config.articleIdentifier)
      if (!articleIdentifier || articleIdentifier != config.articleIdentifier) {
        window.paywallInstance.tearDown()
        window.paywallInstance = new PaywallClass(config)
      }
    })
  }
}

Paywall.on = (eventType: string, callback: Function) => {
  emitter.on(eventType, (event: any) => {
    setTimeout(() => callback(event.data))
  })
}

Paywall.track = async (args: Array<any>, bidDetails?: any) => {
  await window.paywallInstance?.config?.track(args, bidDetails)
}

Paywall.onSimUserLogout = (data: any) => {
  fc_logger.info('firing onSimUserLogout event from sdk')
  removeAuthToken()
  removeRefreshToken()
  return emitter.fire('onSimUserLogout', data)
}

async function VideoPaywall(config: IConfig) {
  if (!config.mediaPlayer) {
    return null
  }

  let initializedPlayerIds: string[] = []

  const playerElements = ((await findPlayerElements(config.mediaPlayer)) as Array<any>) || []

  if (playerElements && playerElements.length > 0) {
    fc_logger.info('Found the media player to attach paywall')
  } else {
    fc_logger.info('The media player is not yet found!')
  }

  playerElements.forEach(async (playerEle) => {
    //@ts-ignore
    let player: IPlayer = await getMediaPlayer(config, playerEle, initializedPlayerIds)
    //In case a player with the same sourceId has already been initialized, player will be returned as null
    if (player.duplicate) {
      return
    }

    initializedPlayerIds.push(player.sourceId)

    Paywall({
      ...config,
      player,
      contentSelector: player.sourceId,
      articleIdentifier: player.sourceId,
      paywallTargetElement: player.targetElement,
      contentType: getContentType(config.mediaPlayer),
    })
  })
}

const AudioPaywall = VideoPaywall

window.Paywall = Paywall
window.VideoPaywall = VideoPaywall
window.AudioPaywall = AudioPaywall

export { Paywall, VideoPaywall, AudioPaywall }
